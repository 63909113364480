<style scoped>
td {
    padding: 5px;
}
</style>
<template>
    <div v-if="authenticated" class="home">
        <div>
            Name: <input v-model="name" />
            <div>
                {{name_validation}}
            </div>
            URL: <input v-model="url" />
            <div>
                {{url_validation}}
            </div>
            <button :disabled="submit_disable" v-on:click="submit">Submit</button>
        </div>
    </div>
</template>
<script>
import { collection, doc, getDoc, setDoc } from 'firebase/firestore';
import { setError } from "@/error"


export default {
    name: 'AddView',
    inject: [
        'authenticated',
        'profile_email',
        'firebase_db'
    ],
    components: {},
    data: function() {
        return {
            name: undefined,
            name_validation: undefined,
            url: undefined,
            url_validation: undefined,
            submit_disable: false,
        }
    },
    watch: {
        authenticated() {
            if (this.authenticated) {
                this.load()
            }
        },
        name() {
            this.validateName()
        },
        url() {
            this.validateUrl()
        }
    },
    created: function() {
        if (this.authenticated) {
            console.log('authenticated')
            this.load()
        }
        this.$watch(
            () => this.$route.params, () => {
                this.load();
            }
        )
    },
    methods: {
        load: async function() {

        },
        validateName: async function() {
            if (!this.name) {
                this.name_validation = 'Name cannot be empty'
                return false;
            }
            if (this.name == "edit") {
                this.name_validation = 'This name is reserved'
                return false;
            }
            if (this.name == "add") {
                this.name_validation = 'This name is reserved'
                return false;
            }

            var nameRegex = /^[a-zA-Z0-9-]+$/g
            if (!this.name.match(nameRegex)) {
                this.name_validation = 'Invalid badge name (/[a-z-]+/)'
                return false
            } else {

                const badgesRef = collection(this.firebase_db, "links");
                const q = doc(badgesRef, this.name);

                var docExists = await getDoc(q).then(doc => {
                    if (doc.data() === undefined) {
                        // Link does not exist yet
                        return false
                    } else {
                        return true
                    }

                }).catch(error => {
                    setError(error)
                });

                if (!docExists) {
                    this.name_validation = undefined
                    return true
                } else {
                    this.name_validation = 'Link with this name already exists'
                    return false
                }

            }
        },
        validateUrl: async function() {
            var urlRegex = /^http[s]*:\/\/\S*$/g
            if (!this.url.match(urlRegex)) {
                this.url_validation = 'Invalid URL'
                return false
            } else {
                this.url_validation = undefined
            }

            return true
        },
        validate: async function() {
            // Validate inputs
            var name_validated = await this.validateName()
            if (!name_validated) {
                return false
            }

            var url_validated = await this.validateUrl()
            if (!url_validated) {
                return false
            }

            return true
        },
        submit: async function() {
            if (!this.authenticated) {
                setError('Authentication failure')
                return
            }

            var validated = await this.validate()
            if (!validated) {
                return
            }

            // Disable submit button
            this.submit_disable = true

            setDoc(doc(this.firebase_db, "links", this.name), {
                url: this.url,
                creator: this.profile_email
            }).then(() => {
                // Success, forward to the badge page.
                this.$router.push('/edit/' + this.name)
            }).catch((error) => {
                setError(error)
            })

        }
    }
}
</script>