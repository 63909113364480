<style scoped>
td {
    padding: 5px;
}
</style>
<template>
    <div v-if="authenticated" class="home">
        <h2>{{id}} <i class="fa fa-cog fa-spin"></i></h2>
        <i>Redirecting...</i>
    </div>
</template>
<script>
import { collection, doc, getDoc } from 'firebase/firestore';
import { setError } from "@/error"


export default {
    name: 'LinkView',
    inject: [
        'authenticated',
        'profile_email',
        'firebase_db'
    ],
    components: {},
    data: function() {
        return {
            link: undefined,
            id: undefined,
            url: ""
        }
    },
    watch: {
        authenticated() {
            if (this.authenticated) {
                this.load()
            }
        }
    },
    created: function() {
        if (this.authenticated) {
            console.log('authenticated')
            this.load()
        }
        this.$watch(
            () => this.$route.params, () => {
                this.load();
            }
        )
    },
    methods: {
        load: async function() {
            this.id = this.$route.params.id;
            console.log("LinkView load");

            document.title = 'go ' + this.id;

            const linksRef = collection(this.firebase_db, "links");
            const q = doc(linksRef, this.id);

            getDoc(q).then(doc => {
                if (doc.data() === undefined) {
                    setError("Could not locate link " + this.id);
                    return;
                }

                console.log(doc.data().url);

                // Directly load the link from the result, set the ID to the document ID.
                //this.link = doc.data();
                //this.link.id = doc.id;
                //this.url = this.link.url;

                // Set the window location to the link URL.
                location.href = doc.data().url;

            })
        }
    }
}
</script>