<style scoped>
td {
    padding: 5px;
}
</style>
<template>
    <div v-if="authenticated" class="home">
        <i v-if="!link" class="fa fa-cog fa-spin"></i>
        <div v-if="link">
            <h2>
                <a v-if="link.alias" :href="link.alias">{{id}}</a>
                &nbsp;
                <button @click="copy"><i class="fa fa-solid fa-cog fa-clipboard"></i></button>
            </h2>
            <div>URL: <input v-model="link.url" v-on:blur="onUrl" :size="Math.max(link.url.length, 20)" />
                <span v-if="url_update">
                    <i class="fa fa-solid fa-cog fa-spin"></i>
                </span>
            </div>
        </div>
    </div>
</template>
<script>
import { collection, doc, getDoc, updateDoc } from 'firebase/firestore';
import { setError } from "@/error"


export default {
    name: 'LinkView',
    inject: [
        'authenticated',
        'profile_email',
        'firebase_db'
    ],
    components: {},
    data: function() {
        return {
            link: undefined,
            id: undefined,
            alias: undefined,
            url_update: false,
        }
    },
    watch: {
        authenticated() {
            if (this.authenticated) {
                this.load()
            }
        }
    },
    created: function() {
        if (this.authenticated) {
            console.log('authenticated')
            this.load()
        }
        this.$watch(
            () => this.$route.params, () => {
                this.load();
            }
        )
    },
    methods: {
        copy: function() {
            navigator.clipboard.writeText('go/' + this.id);
        },
        onUrl: function() {

            var url = this.link.url

            if (!url) {
                return
            }

            url = url.trim()

            if (url.length < 1) {
                return
            }

            this.url_update = true
            updateDoc(doc(this.firebase_db, "links", this.link.id), {
                "url": url
            }).then(() => {
                this.url_update = false
            }).catch((error) => {
                setError(error)
            })
        },
        load: async function() {
            this.id = this.$route.params.id;
            console.log("LinkView load");

            const linksRef = collection(this.firebase_db, "links");
            const q = doc(linksRef, this.id);

            getDoc(q).then(doc => {
                if (doc.data() === undefined) {
                    setError("Could not locate link " + this.id);
                    return;
                }

                // Directly load the link from the result, set the ID to the document ID.
                this.link = doc.data();
                this.link.alias = "/" + doc.id;
                this.link.id = doc.id;
            })
        }
    }
}
</script>