<style scoped>
td {
    padding: 5px;
}
</style>
<template>
    <div v-if="authenticated" class="home">
        <i v-if="!links.length" class="fa fa-cog fa-spin"></i>
        <table class="listtable">
            <tr v-for="link in links" :key="link.id">
                <td>
                    <a :href="link.edit">{{link.id}}</a>
                </td>
                <td>
                    <a :href="link.url">{{link.url}}</a>
                </td>
            </tr>
        </table>
    </div>
</template>
<script>
import { collection, query, getDocs } from 'firebase/firestore';


export default {
    name: 'MainView',
    inject: [
        'authenticated',
        'profile_email',
        'firebase_db'
    ],
    components: {},
    data: function() {
        return {
            links: []
        }
    },
    watch: {
        authenticated() {
            if (this.authenticated) {
                this.load()
            }
        }
    },
    created: function() {
        if (this.authenticated) {
            console.log('authenticated')
            this.load()
        }
        this.$watch(
            () => this.$route.params, () => {
                this.load();
            }
        )
    },
    methods: {
        load: async function() {
            console.log("MainView load");

            const q = query(collection(this.firebase_db, "links"))
            getDocs(q).then(querySnapshot => {
                this.links = []
                querySnapshot.forEach((doc) => {
                    var link = doc.data()
                    link.id = doc.id
                    link.edit = "/edit/" + link.id
                    this.links.push(link)
                })
            })
        }
    }
}
</script>