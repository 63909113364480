import { createRouter, createWebHistory } from 'vue-router'
import MainView from '../views/MainView.vue'
import LinkView from '../views/LinkView.vue'
import EditView from '../views/EditView.vue'
import AddView from '../views/AddView.vue'

const routes = [{
        path: '/',
        component: MainView
    },
    {
        path: '/add',
        component: AddView
    },
    {
        path: '/:id',
        component: LinkView
    },
    {
        path: '/edit/:id',
        component: EditView
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router